import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import { T } from '../helpers/i18n'
import Layout from '../components/partials/layout'
import { Link } from 'gatsby'
import languages from '../../static/languages.json'
import { currentLang } from '../helpers/i18n'

class NotFound extends React.Component {
  render() {
    let { lang } = currentLang()
    const cookies = get(this, 'props.data.contentfulCookies')
    const menuItems = get(this, 'props.data.allContentfulMenuItem.nodes')
    return (
      <Layout menuItems={menuItems} cookies={cookies}>
        <div className="text-center my-12">
          <h1>{T('NOTHING_HERE')}</h1>
          <Link
            className="underline text-tertiary"
            to={`${lang !== languages.defaultLanguage ? `/${lang}` : ''}/`}
          >
            {T('HOME')}
          </Link>
        </div>
      </Layout>
    )
  }
}

export default NotFound

export const pageQuery = graphql`
  query NotFoundQuery($locale: String!) {
    contentfulCookies(node_locale: { eq: $locale }) {
      linkName
      linkUrl
      title
      content
    }
    allContentfulMenuItem(
      sort: { fields: [contentful_id] }
      filter: { node_locale: { eq: $locale } }
    ) {
      nodes {
        code
        name
        url
      }
    }
  }
`
